<template>
  <div id="" class="relative">
    <div class="mx-auto max-w-xl">
      <div class="rounded-3xl">
        <template v-if="loading">
          <div class="mt-60 flex items-center justify-center">
            <LoadingCircle />
          </div>
        </template>
        <template v-if="submitted && !loading">
          <div class="rounded-3xl bg-primary p-5 text-white sm:p-10">
            <h1 class="mb-3 text-3xl font-bold text-white">Vielen Dank für Ihre Anfrage!</h1>
            <div class="prose text-lg text-white">
              <p>Wir werden uns kurzfristig bei Ihnen melden, um alles weitere zu besprechen.</p>
              <p class="text-white">Wie hat Ihnen der Sanierungsrechner gefallen?</p>
              <TrustPilotStatic />
              <p>Vielen Dank für Ihr Vertrauen und viel Erfolg bei Ihrer anstehenden Sanierung.</p>
            </div>
          </div>
          <div class="mt-8 flex flex-row items-center justify-center">
            <button
              @click="redirectToRoot"
              class="btn flex flex-row items-center justify-center gap-x-3 text-xl underline"
            >
              Zurück zur Startseite
            </button>
          </div>
        </template>

        <template v-else-if="!loading">
          <div
            v-if="currentStep === 'costs'"
            class="mt-5 grid grid-cols-1 items-center justify-center gap-5"
          >
            <div class="flex flex-col items-center">
              <h2 class="mb-10 text-center text-2xl" v-if="!providerFound">
                Wir suchen die besten Handwerker in ihrer Region:
              </h2>
              <h2 class="mb-10 text-center text-2xl" v-else>Handwerker gefunden!</h2>
              <div class="relative h-[250px] w-[250px]">
                <div
                  class="absolute inset-0 flex items-center justify-center rounded-full border-4 border-primary p-2"
                >
                  <Loop class="animate-search-around text-[150px]" v-if="!providerFound" />
                  <Yes class="text-[170px]" v-else @click="nextStep" />
                </div>
              </div>
              <div
                class="mt-10 flex flex-col items-center justify-between gap-2 text-xl font-semibold"
                v-if="providerFound"
              >
                <p>Unverbindliche Kostenschätzung:</p>
                <AnimatedNumber :model-value="totals.total.value" class="" />
              </div>
              <div
                class="mt-8 flex justify-between gap-5 rounded-xl bg-primary p-2 text-2xl font-semibold text-action"
                v-if="providerFound"
              >
                <button
                  @click="nextStep"
                  class="btn btn--primary flex w-full flex-row items-center justify-center gap-x-3 text-xl text-action"
                >
                  <span>Kostenlose Angebote erhalten</span>
                </button>
              </div>
            </div>
          </div>

          <fieldset v-if="currentStep === 'contractor'" class="flex flex-col gap-lg">
            <div class="flex flex-col items-center justify-center">
              <h3 class="text-center text-2xl font-bold dark:text-white md:text-3xl">
                Möchten sie eine Sanierung mit einem Generalunternehmen?
              </h3>
              <InfoPanelIcon
                height="h-9 mt-2 md:h-10"
                :content="contractor"
                title="Generalunternehmen"
              >
                <slot name="info" />
              </InfoPanelIcon>
            </div>
            <IconsOptionsSelection
              v-model="model.services.contractor.enabled"
              :options="yesNo"
              cols="md:grid-cols-2"
            />

            <div class="flex flex-row items-center justify-center text-center">
              <p>
                Bei einer Sanierung mit 4 oder mehr unterschiedlichen Gewerken empfehlen wir Ihnen
                die Zusammenarbeit mit einem Generalunternehmen. Die Sanierungskosten erhöhen sich
                dadurch um ca. 15%.
              </p>
            </div>
            <button
              @click="nextStep"
              class="btn btn--primary flex w-full flex-row items-center justify-center gap-x-3 px-3 py-2"
            >
              <span>Weiter </span>
            </button>
          </fieldset>

          <fieldset v-if="currentStep === 'zip'" class="flex flex-col gap-5">
            <div
              class="flex flex-col items-center justify-between gap-2 text-xl font-semibold sm:text-2xl"
            >
              <p class="text-center">Unverbindliche Kostenschätzung:</p>
              <AnimatedNumber
                :model-value="totals.total.value"
                class="text-2xl font-bold underline sm:text-3xl"
              />
            </div>

            <p class="text-center text-xl sm:text-2xl">
              Finden sie bequem Handwerker in Ihrer Nähe!
            </p>
            <input
              type="number"
              id="postal_code"
              v-model="postalCode"
              placeholder="Ihre Postleitzahl"
              class="input input-final"
              @keydown.enter="handleEnterKey"
            />
            <button
              @click="nextStep"
              class="btn btn--primary flex w-full flex-row items-center justify-center gap-x-3 px-3 py-2"
              :disabled="!isPostalCodeValid"
            >
              <span>Weiter </span>
            </button>
            <button
              v-if="contractorStep"
              @click="previousStep('contractor')"
              class="flex w-full flex-row items-center justify-center gap-x-3 px-3 underline"
            >
              <span>zurück </span>
            </button>
          </fieldset>

          <fieldset v-if="currentStep === 'name'" class="grid grid-cols-1 gap-4">
            <div>
              <p class="text-center text-xl font-bold">
                Innerhalb von 24 Stunden erhalten Sie die ersten Angebote!
              </p>
              <p class="text-md text-center sm:text-lg">(Wochenenden und Feiertage ausgenommen)</p>
            </div>

            <input
              type="text"
              v-model="model.contact.fullName"
              id="fullname"
              placeholder="Vollständiger Name (Vorname und Nachname)"
              class="input input-final"
            />
            <input
              type="email"
              id="email"
              v-model="model.contact.email"
              placeholder="Ihre E-Mail"
              class="input input-final"
            />
            <p class="text-md text-center sm:text-lg">
              Nur mit der richtigen Telefonnummer, erreichen Sie die Handwerker schnell und bequem.
            </p>
            <input
              type="text"
              id="phone"
              name="phone"
              :ref="setPhoneInputRef"
              v-model="phoneComputed"
              placeholder="Ihre Telefonnummer"
              class="input input-final pl-[40px]"
            />
            <p class="mt-[-10px] text-red-500" v-if="phoneError">{{ phoneError }}</p>

            <button
              id="submit"
              type="submit"
              name="submit"
              @click="isProtected.value = false"
              class="btn btn--primary flex w-full flex-row items-center justify-center gap-x-3 px-3 py-2"
              :disabled="submitting || isFormInvalid()"
            >
              <Loading v-if="submitting" class="my-1" />
              <span v-else>Unverbindliche Angebote erhalten</span>
            </button>
            <button
              @click="previousStep('zip')"
              class="flex w-full flex-row items-center justify-center gap-x-3 px-3 py-2 underline"
            >
              <span>zurück </span>
            </button>
          </fieldset>
        </template>
      </div>
    </div>

    <div class="mt-5 rounded-b-3xl bg-white sm:p-10" v-if="!isProtected">
      <!--
      <slot :totals="totals" :model="model" :goBack="goBack">
        <h2 class="mb-10 text-2xl">Unsere Kostenschätzung</h2>
        <div class="mb-10">
          <div v-for="i in totals.services" class="mb-5 border-b-2 border-primary pb-5">
            <div class="mb-1 flex justify-between">
              <h3 class="text-lg">{{ i.name }}</h3>
              <p class="text-lg font-bold">
                <AnimatedNumber :model-value="i.total.value" />
              </p>
            </div>
          </div>
          <div class="flex items-end justify-between">
            <div class="">
              <button type="button" class="btn btn--primary" @click="goBack">Zum Anfang</button>
            </div>
            <p class="text-4xl font-bold">
              <AnimatedNumber :model-value="totals.total.value" />
            </p>
          </div>
        </div>
      </slot>
      -->
    </div>
  </div>
</template>

<script setup>
import Loading from '@/components/Elements/Loading.vue'
import track from '@/utils/tracking'
import { defineProps, defineEmits, computed, onMounted, ref, watch, nextTick } from 'vue'
import intlTelInput from 'intl-tel-input'
import 'intl-tel-input/build/css/intlTelInput.css'
import AnimatedNumber from '@/components/Inputs/AnimatedNumber.vue'
import Loop from '@/components/Icons/Loop.vue'
import Yes from '@/components/Icons/Yes.vue'
import IconsOptionsSelection from '@/components/Inputs/IconsOptionsSelection.vue'
import { yesNo } from '@/data/options/v2/baths.js'
import InfoPanelIcon from '@/components/Elements/InfoPanelIcon.vue'
import { contractor } from '@/data/infos/contractor.js'
import LoadingCircle from '@/components/Elements/LoadingCircle.vue'
import TrustPilotStatic from '@/components/Elements/Trustpilot/TrustpilotStatic.vue'

const emit = defineEmits(['update:modelValue'])
const props = defineProps({
  modelValue: Object,
  totals: Object,
  submitted: Boolean,
  submitting: Boolean,
  options: {
    type: Array,
    required: false
  },
  loading: Boolean
})

const model = computed({
  get: () => props.modelValue,
  set: (v) => emit('update:modelValue', v)
})

const enabledServices = computed(() => {
  return Object.keys(model.value.services).filter((key) => model.value.services[key].enabled)
})

const isProtected = ref(true)
const steps = ['contractor', 'zip', 'name']
const currentStep = ref('')

const isFormInvalid = () => {
  return (
    !model.value.base.postal_code ||
    !model.value.contact.fullName ||
    !model.value.contact.email ||
    !model.value.contact.phone
  )
}

const isPostalCodeValid = computed(() => {
  return model.value.base.postal_code && model.value.base.postal_code.toString().length === 5
})
// watch fullName and split it into firstname and lastname
watch(
  () => model.value.contact.fullName,
  (newVal) => {
    console.log('newVal', newVal)
    const [firstName, ...lastNameParts] = newVal.split(' ')
    model.value.contact.firstname = firstName || ''
    model.value.contact.lastname = lastNameParts.join(' ') || 'x'
  },
  { deep: true }
)

const nextStep = () => {
  const currentIndex = steps.indexOf(currentStep.value)
  if (currentIndex < steps.length - 1) {
    currentStep.value = steps[currentIndex + 1]
  }
}
const handleEnterKey = (e) => {
  if (e.key === 'Enter') {
    nextStep()
  }
}

const previousStep = (step) => {
  if (step) {
    currentStep.value = step
    return
  }
  const currentIndex = steps.indexOf(currentStep.value)
  if (currentIndex > 0) {
    currentStep.value = steps[currentIndex - 1]
  }
}

const postalCode = computed({
  get: () => model.value.base.postal_code,
  set: (v) => {
    model.value.base.postal_code = v
    model.value.contact.postal_code = v
  }
})

//initialize intlTelInput
const phoneInput = ref(null)
let iti = ref()

const setPhoneInputRef = (el) => {
  phoneInput.value = el
}
const initializeIntlTelInput = () => {
  if (phoneInput.value) {
    iti.value = intlTelInput(phoneInput.value, {
      initialCountry: 'de',
      showFlags: true,
      useFullscreenPopup: false,
      utilsScript: 'https://cdn.jsdelivr.net/npm/intl-tel-input@23.3.2/build/js/utils.js'
    })
  }
}

const phoneError = ref('')
const phone = ref(model.value.contact.phone)

watch(currentStep, (newStep) => {
  if (newStep === 'name') {
    nextTick(() => {
      initializeIntlTelInput()
    })
  }
})

const phoneComputed = computed({
  get: () => {
    return phone.value
  },
  set: (v) => {
    phone.value = v
    model.value.contact.phone = v
    if (iti && iti.value.isValidNumber()) {
      model.value.contact.phone = iti.value.getNumber()
      phoneError.value = ''
    } else {
      phoneError.value = 'Bitte geben Sie eine gültige Telefonnummer ein.'
      model.value.contact.phone = ''
    }
  }
})

const providerFound = ref(false)

watch(currentStep, (newStep) => {
  if (newStep === 'costs') {
    providerFound.value = false // Reset to false when entering 'costs' step
    setTimeout(() => {
      providerFound.value = true
    }, 3000) // 3000 milliseconds = 3 seconds
  }
})

const redirectToRoot = () => {
  window.location.href = '/'
}

const contractorStep = ref(false)

watch(
  () => model.value.step,
  (newValue) => {
    if (enabledServices.value.length >= 4) {
      currentStep.value = 'contractor'
      contractorStep.value = true
    } else {
      currentStep.value = 'zip'
      contractorStep.value = false
    }
  }
)

watch(
  () => enabledServices.value,
  (newValue, oldValue) => {
    console.log('enabledServices changed', newValue, oldValue)
    if (!newValue.length) {
      model.value.step = 'services'
    }
  }
)

onMounted(() => {
  if (enabledServices.value.length >= 4) {
    currentStep.value = 'contractor'
    contractorStep.value = true
  } else {
    currentStep.value = 'zip'
    contractorStep.value = false
  }
  model.value.contact.postal_code = model.value.base.postal_code
  track.yandex(97558944, 'reachGoal', 'contactPage')
  track.tmBegin(model.value.type, props.totals.total.value)

  /*  if (typeof reviewsBadgeRibbon !== 'undefined' && !props.submitted)
      reviewsBadgeRibbon('badge-ribbon', {
        store: 'www.moreathome.de',
        size: 'small',
        mono: true
      })*/
})
</script>

<style>
@import 'intl-tel-input/build/css/intlTelInput.css';

.iti {
  --iti-dropdown-bg: white;
  --iti-selected-country-arrow-padding: 49px;
  --iti-input-padding: 6px;
  --iti-path-flags-1x: url('/node_modules/intl-tel-input/build/img/flags.png');
  --iti-path-flags-2x: url('/node_modules/intl-tel-input/build/img/flags@2x.png');
  --iti-path-globe-1x: url('/node_modules/intl-tel-input/build/img/globe.png');
  --iti-path-globe-2x: url('/node_modules/intl-tel-input/build/img/globe@2x.png');
  --iti-border-gray: #ccc;
  --iti-text-gray: #999;
  --iti-hover-color: rgba(0, 0, 0, 0.05);
  --iti-border-color: var(--iti-border-gray);
  --iti-dialcode-color: var(--iti-text-gray);
  --iti-spacer-horizontal: 8px;
  --iti-flag-height: 15px;
  --iti-flag-width: 20px;
  --iti-border-width: 1px;
  --iti-arrow-height: 4px;
  --iti-arrow-width: 6px;
  --iti-triangle-border: calc(var(--iti-arrow-width) / 2);
  --iti-arrow-padding: 6px;
  --iti-arrow-color: #555;
  --iti-right-hand-selected-country-padding: calc(
    var(--iti-spacer-horizontal) + var(--iti-spacer-horizontal) + var(--iti-flag-width)
  );
  --iti-flag-sprite-width: 5762px;
  --iti-flag-sprite-height: 15px;
  --iti-mobile-popup-margin: 30px;
}
</style>
